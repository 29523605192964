html.main {
    height: 100%;
}

body.main {
    min-height: 100%;
}

main {
    padding: 0 1rem 1rem 1rem;
}

main > hgroup h1 {
    font-size: 1.75rem;
}

main > hgroup h2 {
    font-size: 1.55rem;
}

.event-card {
    min-width: 50%;
    padding: 1.25rem;
}

.event-card:last-child {
    margin-right: auto;
    margin-left: auto;
}

.event-card-body {
    padding: 1.25rem;
    height: 100%;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.event-card h1 {
    font-size: 1.5rem;
}

.event-card h2 {
    font-size: 1rem;
    color: #6c757d!important;
}

.event-card .btn {
    margin: .125rem;
    width: 8rem;
}
nav.navbar-dark {
    background: #333333;
}
.progress {
    position: relative;
}

.progress > .detail {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    font-size: 1.3rem;
}

tr[role="button"] {
    cursor: pointer;
}